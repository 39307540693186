import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import {useSelector} from 'react-redux'
import { createConsumer } from '@rails/actioncable'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import LoginScreen from './screens/LoginScreen'
import OrderScreen from './screens/OrderScreen'
import OrderDetailIdScreen from './screens/OrderDetailIdScreen'
import HistoryScreen from './screens/HistoryScreen'
import SettingScreen from './screens/SettingScreen'
import MapScreen from './screens/MapScreen'
import { PrivateWrapper } from './component/Layout/PrivateWrapper'
import Header from './component/Header/Header'
import MenuScreen from './screens/MenuScreen'
import { wss_url } from './redux/api/ApiUtills'

function App() {
  const userLogin = useSelector(state=>state.userLogin);
  const { isLoggedIn } = userLogin;
  const cable = createConsumer(wss_url);

  useEffect(() => {
    if (isLoggedIn) {
      cable.subscriptions.create("NotificationChannel", {
        connected() {
          console.log("connected")
        },
        received(data) {
          const audio = document.querySelector("#audio")

          toast.info(data.message)
          audio.setAttribute("allow", "autoplay")
          audio.setAttribute("src", data.sound)
        },
        disconnected() {
          console.log("disconnected")
        }
      })
    }

    return () => {
      cable.subscriptions.remove("NotificationChannel")
    };
  }, [])

  return (
    <Router>
     { isLoggedIn ? <Header /> : null}
      <Routes>
        <Route path="/" element={<LoginScreen/>} />
        <Route element={<PrivateWrapper auth={{ isLoggedIn }} />} >
          <Route path="/orders" element={<OrderScreen />} />
        </Route>
        <Route element={<PrivateWrapper auth={{ isLoggedIn }} />} >
          <Route path="/history" element={<HistoryScreen />} />
        </Route>
        <Route element={<PrivateWrapper auth={{ isLoggedIn }} />} >
          <Route path="/setting" element={<SettingScreen />} />
        </Route>
        <Route element={<PrivateWrapper auth={{ isLoggedIn }} />} >
          <Route path="/orders/:id" element={<OrderDetailIdScreen />} />
        </Route>
        <Route element={<PrivateWrapper auth={{ isLoggedIn }} />} >
          <Route path="/map" element={<MapScreen />} />
        </Route>
        <Route element={<PrivateWrapper auth={{ isLoggedIn }} />} >
          <Route path="/menu" element={<MenuScreen />} />
        </Route>
      </Routes>
      <iframe id="audio" style={{display: "none"}}></iframe>
      <ToastContainer autoClose={2000} position="top-center" />
    </Router>
  );
}

export default App;
